import { Grommet, Box } from "grommet";
import ImdbConnector from "./components/ImdbConnector";

const theme = {
  global: {
    colors: {
      brand: '#BDE0FE',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

const AppBar = (props) => (
  <Box
    tag='header'
    direciton='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  />
)

function App() {
  return (
    <Grommet theme={theme}>
      <AppBar>Media Journey</AppBar>
      <Box margin='medium'>
        <ImdbConnector />
      </Box>
    </Grommet>
  );
}

export default App;
