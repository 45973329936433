import { Text, TextInput, Box, Button, RangeInput, DateInput, CheckBox, TextArea, Spinner } from 'grommet';
import React from 'react'
import axios from 'axios';
import Airtable from 'airtable';

export default function ImdbConnector() {
  const [imdbValue, setImdbValue] = React.useState('');
  const [doubanValue, setDoubanValue] = React.useState('');
  const [rating, setRating] = React.useState(5);
  const [startDate, setStartDate] = React.useState(new Date());
  const [seenDate, setSeenDate] = React.useState(new Date());
  const [seen, setSeen] = React.useState(true);
  const [otherName, setOtherName] = React.useState('');
  const [notes, setNotes] = React.useState('');
  const [result, setResult] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    if (result.includes('error')) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [result]);

  function connectImdb() {
    if (imdbValue.length <= 6) return;
    let imdbId = imdbValue;
    if (imdbId.includes('imdb.com')) {
      const rx = /imdb.com\/title\/(.*)\//g;
      const match = rx.exec(imdbValue);
      imdbId = match[1];
    }
    fetchImdbTitle(imdbId);
  }

  function updateResult(message) {
    setResult(message);
    setLoading(false);
  }

  function fetchImdbTitle(imdbId) {
    setLoading(true);
    const url = `https://imdb-api.com/en/API/Title/${process.env.REACT_APP_IMDB_API_KEY}/${imdbId}`;
    let info = {};
    // let info = {
    //   fullTitle: "Top Gun: Maverick (2022)",
    //   genres: "Action, Drama",
    //   id: "tt1745960",
    //   imDbRating: "8.6",
    //   image: "https://imdb-api.com/images/original/MV5BMmIwZDMyYWUtNTU0ZS00ODJhLTg2ZmEtMTk5ZmYzODcxODYxXkEyXkFqcGdeQXVyMTEyMjM2NDc2._V1_Ratio0.6751_AL_.jpg",
    //   metacriticRating: "78",
    //   originalTitle: "",
    //   releaseDate: "2022-05-27",
    //   title: "Top Gun: Maverick",
    //   type: "Movie",
    //   year: "2022",
    // }
    // pushToAirtable(info);
    if (!process.env.REACT_APP_IMDB_API_KEY) {
      updateResult('error: invalid key');
      return;
    }
    axios.get(url).then(res => {
      console.log(res);
      const data = res.data;
      if (data.errorMessage) {
        updateResult(`error: ${data.errorMessage}`);
        return;
      }
      info = (({
        id, fullTitle, title, year, genres, imDbRating, image, metacriticRating, originalTitle, releaseDate, type
      }) => ({
        id, fullTitle, title, year, genres, imDbRating, image, metacriticRating, originalTitle, releaseDate, type
      }))(data);
      pushToAirtable(info);
    })
  }

  function pushToAirtable(info) {
    Airtable.configure({
      endpointUrl: 'https://api.airtable.com',
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY
    });
    const base = Airtable.base(process.env.REACT_APP_BASE_ID);
    const title = otherName ? `${otherName} ${info.title}` : info.title;
    base('MovieInbox').create([
      {
        "fields": {
          "Name": title,
          "Year": Number(info.year),
          "Photos": [{ "url": info.image }],
          "Seen?": seen,
          "Seen Date": seenDate,
          "Start Date": startDate,
          "Genre": info.genres.split(', '),
          "My Rating": rating,
          "Notes": notes,
          "豆瓣": doubanValue,
          "IMDB": `https://www.imdb.com/title/${info.id}`,
          "IMDB Rating": Number(info.imDbRating)
        }
      }
    ], {"typecast": true }, function(err, records) {
      if (err) {
        console.error(err);
        updateResult(`error: ${err}`);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
        updateResult(`success!`);
      });
    });
  }

  function updateStartDate(value) {
    setStartDate(new Date(value));
    setSeenDate(new Date(value));
  }

  function reset() {
    setImdbValue('');
    setDoubanValue('');
    setOtherName('');
    setNotes('');
    setResult('');
  }

  return (
    <Box width={{ min: '370px', max: '100%' }} margin={{ horizontal: 'auto' }}>
      <Box margin={{ vertical: 'xsmall' }}>
        <TextInput
          placeholder="IMDB Link/ID"
          id="imdb-input"
          name="imdb-input"
          value={imdbValue}
          onChange={(event) => setImdbValue(event.target.value)}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <TextInput
          placeholder="Douban Link/ID"
          id="douban-input"
          name="douban-input"
          value={doubanValue}
          onChange={(event) => setDoubanValue(event.target.value)}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <TextInput
          placeholder="Other Name"
          id="other-name-input"
          name="other-name-input"
          value={otherName}
          onChange={(event) => setOtherName(event.target.value)}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }} direction="row" justify='between'>
        <Box direction="row" align='center'>
          <Text margin={{ right: 'small'}}>Rating</Text>
          <RangeInput
            max='5'
            value={rating}
            onChange={event => setRating(event.target.value)}
          />
          <Text margin={{ left: 'small'}}>{rating}</Text>
        </Box>
        <Box direction="row" align='center'>
          <CheckBox
            checked={seen}
            label="Seen？"
            onChange={(event) => setSeen(event.target.checked)}
          />
        </Box>
      </Box>
      <Box margin={{ vertical: 'xsmall' }} direction="row" align='center'>
        <Text margin={{ right: 'small'}}>Start</Text>
        <DateInput
          format="mm/dd/yyyy"
          value={startDate.toISOString()}
          onChange={({ value }) => updateStartDate(value)}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }} direction="row" align='center'>
        <Text margin={{ right: 'small'}}>Seen</Text>
        <DateInput
          format="mm/dd/yyyy"
          value={seenDate.toISOString()}
          onChange={({ value }) => setSeenDate(new Date(value))}
        />
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <TextArea
          placeholder="Notes"
          value={notes}
          onChange={event => setNotes(event.target.value)}
        />
      </Box>
      <Box direction="row" margin={{ vertical: 'small' }} justify='between'>
        <Button primary label="Submit" disabled={imdbValue.length < 6 } onClick={() => connectImdb()} />
        <Button secondary label="Reset" onClick={() => reset()} />
      </Box>
      <Box margin={{ vertical: 'small' }}>
        { loading && <Spinner /> }
        { result &&
          <Text color={ hasError ? 'red' : 'green' }>{result}</Text>
        }
      </Box>
    </Box>
  )
}
